<template>
  <div>
    <Divider dashed><span class="divider-text">任务汇总</span></Divider>
    <Table stripe class="m-b-5" size="small" :data="dataList" :columns="dataColumns"></Table>
    <Divider dashed><span class="divider-text">其他任务操作</span></Divider>
    <div class="m-b-5">
      <Button type="primary" size="small" long @click="handleApplicationCancel">
        <Badge :count="revokeTotal" :offset="[0, -10]">
          处理撤销申请
        </Badge>
      </Button>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { getTaskitemTypeStatistic } from '@/api/msp/plan'
import { getApplyRevokeQuantity } from '@/api/msp/terminate'
export default {
  mixins: [sysMixins],
  data () {
    return {
      dataList: [],
      dataColumns: [
        { title: '任务类别', align: 'left', key: 'typeName' },
        { title: '总数', align: 'center', key: 'totalQuantity' },
        { title: '执行中', align: 'center', key: 'executingQuantity' },
        { title: '已完成', align: 'center', key: 'finishedQuantity' }
      ],
      revokeTotal: 0
    }
  },
  mounted () {
    this.initData()
    this.getRevokeCount()
  },
  methods: {
    initData () {
      getTaskitemTypeStatistic().then(res => {
        if (res && !res.errcode) {
          this.dataList = res
        }
      })
    },
    /**
     * 获取撤销任务总量
     */
    getRevokeCount () {
      getApplyRevokeQuantity({ operRole: 2 }).then(res => {
        if (res && !res.errcode) {
          this.revokeTotal = res
        }
      })
    },
    handleApplicationCancel () {
      this.handelRevokePage()
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'supplierTaskCancel', value: '撤销申请列表' })
      this.setLinkRouterMaps(['supplierTaskCancel', () => { this.handelRevokePage() }])
      this.setActivedTagName('supplierTaskCancel')
    },
    /**
     * 处理取消任务界面
     */
    handelRevokePage () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setLeftComponent('')
      this.setBottomComponent('CancelTaskTable')
      this.setRightComponent('')
    }
  }
}
</script>
